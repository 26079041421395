import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Modal, Form } from 'antd';
import axios from 'axios';
import { isAuthenticated } from '../../../components/Auth/auth';
import { ErrorAlert, SuccessAlert } from '../../../components/Messages/Messages';
import SignatureCanvas from 'react-signature-canvas';
import './ContractDetails.css'; // Import your CSS file for styling
import { MoneyCollectOutlined, SendOutlined } from '@ant-design/icons';
import Loading from '../../../components/Loading/Loading';

const ContractDetails = () => {
    const router = useHistory();
    const { id } = useParams();
    const sigCanvas = useRef({});
    const [loading, setLoading] = useState(false);
    const [contract, setContract] = useState(null);
    const [visible, setVisible] = useState(false);

    // Fetch contract details from backend API
    const getContractById = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/contracts/contract/${id}`, {
                headers: {
                    authorization: 'Bearer ' + localStorage.getItem('token')
                }
            });
            setLoading(false);
            if (response.status === 200 || response.status === 304) {
                setContract(response.data);
            } else {
                ErrorAlert(response?.data?.errorMessage);
            }
        } catch (error) {
            setLoading(false);
            console.error('Error fetching contract:', error);
        }
    };

    useEffect(() => {
        getContractById();
    }, [id]);

    const showModal = () => {
        setVisible(true);
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const clearSignature = () => {
        sigCanvas.current.clear();
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        const signatureData = sigCanvas.current.toDataURL();

        axios.put(`${process.env.REACT_APP_BACKEND_URL}/contracts/contract/sign/${id}`, { signature: signatureData }, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('token')
            }
        })
            .then(res => {
                setLoading(false);
                SuccessAlert(res.data.successMessage);
                setVisible(false);
                setContract(prevContract => ({
                    ...prevContract,
                    signature: signatureData, // Update contract signature locally
                    status: 'Signed' // Update contract status locally
                }));
            })
            .catch(err => {
                setLoading(false);
                ErrorAlert(err?.response?.data?.errorMessage || 'Failed to sign contract');
            });
    };

    const handleSendContractToClient = (client, user) => {
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/contracts/send-to-client/${id}`, {
            user, client, frontendUrl: window.location.origin
        }, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        })
            .then(res => {
                SuccessAlert(res.data.successMessage);
            })
            .catch(err => {
                ErrorAlert(err?.response?.data?.errorMessage || 'Failed to send contract to client');
            });
    };

    const handlePrint = () => {
        window.print();
    };

    const handlePaymentRoute = () => {
        let calculatedPrice = contract?.totalAmount - contract?.payments?.reduce((a, b) => a + parseInt(b?.amount), 0);
        localStorage.setItem("totalPrice", calculatedPrice);
        localStorage.setItem("currency", contract?.currency);
        router.push(`/payment/${id}`)
    };

    return (
        <div className="ContractDetails">
            {
                loading ?
                    <Loading />
                    :
                    (
                        <>
                            <div className='header'>
                                <h3>
                                    {contract?.title}
                                </h3>
                            </div>
                            <div className='text-end no-print'>
                                <Button type='primary' onClick={handlePrint}>Print Contract</Button>
                            </div>
                            {
                                isAuthenticated()?.role === 0 &&
                                <div className='text-end no-print mt-5'>
                                    <Button icon={<SendOutlined />} type='primary' onClick={() => handleSendContractToClient(contract?.client, contract?.user)}>Send to client</Button>
                                </div>
                            }
                            <div className='mb-3' dangerouslySetInnerHTML={{ __html: contract?.description }}></div>
                            <div className='contractFullDetails'>
                                <div className='item text-end'>
                                    <h3>Client:</h3>
                                    <div>
                                        <h3>
                                            {contract?.client?.fullName}
                                        </h3>
                                        <p>
                                            {contract?.client?.email}
                                        </p>
                                    </div>
                                </div>
                                <div className='item text-end'>
                                    <h3>Employee:</h3>
                                    <div>
                                        <h3>
                                            {contract?.user?.fullName}
                                        </h3>
                                        <p>
                                            {contract?.user?.email}
                                        </p>
                                    </div>
                                </div>
                                <div className='item'>
                                    <h3>Start Date:</h3>
                                    <h3>
                                        {contract?.startDate}
                                    </h3>
                                </div>
                                <div className='item'>
                                    <h3>End Date:</h3>
                                    <h3>
                                        {contract?.endDate}
                                    </h3>
                                </div>
                                <div className='item'>
                                    <h3>Payment Status:</h3>
                                    <h3>
                                        {contract?.paymentStatus}
                                    </h3>
                                </div>
                                <div className='item'>
                                    <div className='w-100'>
                                        <h3>Items / Breakdown:</h3>
                                        <div className='items'>
                                            {contract?.items?.map((item, index) => {
                                                return (
                                                    <div key={index} className='breakdown'>
                                                        <h3>Description:{item?.description}</h3>
                                                        <h3>Price: {item?.price} {contract?.currency} </h3>
                                                        <h3>Duration: {item?.duration}</h3>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                                <div className='item'>
                                    <h3>Total Amount:</h3>
                                    <h3>
                                        {contract?.currency} {contract?.totalAmount}
                                    </h3>
                                </div>
                                <div className='item'>
                                    <h3>Balance:</h3>
                                    <h3>
                                        {contract?.currency} {parseInt(contract?.totalAmount) - contract?.payments?.reduce((a, b) => a + parseInt(b?.amount), 0)}
                                    </h3>
                                </div>
                                <div className='item'>
                                    <div className='w-100'>
                                        <h3>Payments Made:</h3>
                                        <div className='items'>
                                            {contract?.payments?.map((item, index) => {
                                                return (
                                                    <div key={index} className='breakdown'>
                                                        <h3>Description: {item?.description}</h3>
                                                        <h3>Amount: {contract?.currency} {item?.amount}</h3>
                                                        <h3>Date: {item?.date}</h3>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                                <div className='item'>
                                    <h3>Files:</h3>
                                    <div className='files'>
                                        {
                                            contract?.files && contract?.files.length > 0 ? (
                                                <ul className='d-flex align-items-center flex-wrap list-style-none gap-2'>
                                                    {contract?.files.map((file, index) => (
                                                        <li key={index}>
                                                            <img src={file?.url} style={{ width: "64px", height: "64px" }} />
                                                        </li>
                                                    ))}
                                                </ul>
                                            ) : (
                                                <p>No files attached</p>
                                            )
                                        }
                                    </div>
                                </div>
                                {
                                    contract?.status === "Signed" &&
                                    <div className=''>
                                        <div className='signature'>
                                            <div className='text-end ml-auto'>
                                                <h3>Signature</h3>
                                                <img src={contract?.signature} />
                                                <h5 className='mt-4'>By: {contract?.client?.fullName}</h5>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>

                            {contract?.status === 'Pending' && (
                                <div className="sign-section">
                                    <div className='text-end'>
                                        <Button type="primary" onClick={showModal}>Sign Contract</Button>
                                    </div>
                                    <Modal
                                        title="Sign Contract"
                                        visible={visible}
                                        onCancel={handleCancel}
                                        footer={[
                                            <Button key="clear" onClick={clearSignature}>
                                                Clear
                                            </Button>,
                                            <Button key="cancel" onClick={handleCancel}>
                                                Cancel
                                            </Button>,
                                            <Button key="submit" type="primary" loading={loading} onClick={handleSubmit}>
                                                Sign
                                            </Button>,
                                        ]}
                                    >
                                        <Form onSubmit={handleSubmit}>
                                            <Form.Item label="Please sign below">
                                                <SignatureCanvas
                                                    ref={sigCanvas}
                                                    canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
                                                />
                                            </Form.Item>
                                        </Form>
                                    </Modal>
                                </div>
                            )}
                        </>
                    )}
            {
                isAuthenticated()?.role === 1 && contract?.paymentStatus !== "Fully Paid" &&
                <div className='text-end no-print mt-5'>
                    <Button icon={<MoneyCollectOutlined />} type='primary' onClick={handlePaymentRoute}>
                        Make Payment
                    </Button>
                </div>
            }
        </div>
    );
};

export default ContractDetails;
