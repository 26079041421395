import React, { useState, useEffect } from 'react';
import { Button, Input, Form, DatePicker, Select, Popconfirm, Row } from 'antd';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import { ErrorAlert, SuccessAlert } from '../../../components/Messages/Messages';
import DragUpload from '../../../components/DragUpload/DragUpload';
import { isAuthenticated } from '../../../components/Auth/auth';
import ReactQuill from 'react-quill';
import moment from "moment";
import './Contracts.css';
import Loading from '../../../components/Loading/Loading';
import ManualPayments from '../../../components/ManualPayments/ManualPayments';
import { DeleteOutlined } from '@ant-design/icons';

const { Option } = Select;

const UpdateContract = () => {
    const { id } = useParams();
    const router = useHistory();
    const [loading, setLoading] = useState(false);
    const [clients, setClients] = useState([]);
    const [paymentRecords, setPaymentRecords] = useState([]);
    const [paymentsUpdated, setPaymentsUpdated] = useState(false);
    const [items, setItems] = useState([{ description: '', price: '', duration: '' }]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [formData, setFormData] = useState({
        user: isAuthenticated()?._id,
        client: '',
        title: '',
        files: [],
        startDate: '',
        endDate: '',
        currency: 'USD',
        description: '',
        createdBy: isAuthenticated()?._id
    });


    const getContractById = async () => {
        setLoading(true)
        // Fetch contract details by ID
        await axios.get(`${process.env.REACT_APP_BACKEND_URL}/contracts/contract/${id}`, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('token')
            }
        })
            .then(response => {
                const contractData = response.data;
                setPaymentRecords(response.data?.payments);
                setFormData({
                    user: contractData?.user?._id,
                    client: contractData?.client?._id,
                    title: contractData?.title,
                    files: contractData?.files,
                    startDate: contractData?.startDate,
                    endDate: contractData?.endDate,
                    currency: contractData?.currency,
                    description: contractData?.description,
                    createdBy: contractData?.createdBy._id
                });
                setItems(contractData?.items);
                calculateTotalAmount(contractData?.items);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                ErrorAlert(error?.message);
                console.error('Error fetching contract details:', error);
            });
    }

    useEffect(() => {
        // Fetch clients from backend API
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/users/all-clients`, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('token')
            }
        })
            .then(response => {
                setClients(response.data);
            })
            .catch(error => {
                console.error('Error fetching clients:', error);
            });

        getContractById();


    }, [id]);

    useEffect(() => {
        calculateTotalAmount(items);
    }, [items]);

    const handleChange = (key, value) => {
        setFormData({
            ...formData,
            [key]: value
        });
    };

    const handleItemChange = (index, key, value) => {
        const newItems = [...items];
        newItems[index][key] = value;
        setItems(newItems);
    };

    const calculateTotalAmount = (items) => {
        const total = items.reduce((acc, item) => acc + (parseFloat(item.price) || 0), 0);
        setTotalAmount(total);
    };

    const addItem = () => {
        setItems([...items, { description: '', price: '', duration: '' }]);
    };

    const removeItem = (index) => {
        const newItems = items.filter((_, itemIndex) => itemIndex !== index);
        setItems(newItems);
    };

    const submitHandler = (e) => {
        e.preventDefault();
        setLoading(true);
        formData.files = formData.files?.filter(f => f !== null);
        const finalData = { ...formData, items, totalAmount, paymentsUpdated };
        axios.put(`${process.env.REACT_APP_BACKEND_URL}/contracts/update/${id}`, finalData, {
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('token')
            }
        })
            .then(res => {
                setLoading(false);
                SuccessAlert(res.data.successMessage);
                router.push("/");
            })
            .catch(err => {
                setLoading(false);
                console.error('Error updating contract:', err);
                ErrorAlert(err?.response?.data?.errorMessage || 'Failed to update contract');
            });
    };

    const deletePaymentRecord = (paymentId) => {
        axios.put(`${process.env.REACT_APP_BACKEND_URL}/contracts/remove-payment`, { paymentId, contractId: id }, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        })
            .then(res => {
                SuccessAlert(res.data.successMessage);
                getContractById();
                setPaymentsUpdated(true);
            })
            .catch(err => {
                console.error('Error deleteing contract payment:', err);
                ErrorAlert(err?.response?.data?.errorMessage || 'Failed to delete contract payment');
            });
    };

    const handleRefreshData = () => {
        getContractById();
        setPaymentsUpdated(true);
    }

    return (
        <div className='ManageContracts'>
            <h2>Update Contract</h2>
            {
                loading ?
                    <Loading />
                    :
                    <form onSubmit={submitHandler}>
                        <Form.Item label="Title" requiredMark>
                            <Input required value={formData.title} onChange={(e) => handleChange("title", e.target.value)} />
                        </Form.Item>
                        <Form.Item label="Terms & Description" requiredMark required>
                            <ReactQuill
                                className='quill'
                                value={formData.description}
                                onChange={(value) => handleChange("description", value)}
                                placeholder="Enter contract terms and description"
                            />
                        </Form.Item>
                        <Form.Item label="Client" required requiredMark>
                            <Select
                                value={formData.client}
                                onChange={(value) => handleChange("client", value)}
                                placeholder="Select Client"
                            >
                                {clients.map(client => (
                                    <Option key={client._id} value={client._id}>{client.fullName}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item label="Files">
                            <DragUpload
                                accept="image/*"
                                updateFiles={(val) => handleChange("files", val)}
                                defaultFiles={formData.files}
                            />
                        </Form.Item>
                        <Form.Item label="Start Date">
                            <DatePicker
                                className='w-100'
                                value={formData.startDate ? moment(formData.startDate) : null}
                                onChange={(date, dateString) => handleChange("startDate", dateString)}
                            />
                        </Form.Item>
                        <Form.Item label="End Date">
                            <DatePicker
                                className='w-100'
                                value={formData.endDate ? moment(formData.endDate) : null}
                                onChange={(date, dateString) => handleChange("endDate", dateString)}
                            />
                        </Form.Item>
                        <Form.Item label="Currency" required>
                            <Select
                                value={formData.currency}
                                onChange={(value) => handleChange("currency", value)}
                            >
                                <Option value="USD">USD</Option>
                                <Option value="EUR">EUR</Option>
                            </Select>
                        </Form.Item>
                        <div className="items-section">
                            <h3 className='text-start'>Items</h3>
                            {items.map((item, index) => (
                                <div key={index} className="item-row">
                                    <Input
                                        placeholder="Description"
                                        value={item.description}
                                        onChange={(e) => handleItemChange(index, "description", e.target.value)}
                                    />
                                    <Input
                                        type='number'
                                        placeholder="Price"
                                        value={item.price}
                                        onChange={(e) => handleItemChange(index, "price", e.target.value)}
                                    />
                                    <Input
                                        placeholder="Duration"
                                        value={item.duration}
                                        onChange={(e) => handleItemChange(index, "duration", e.target.value)}
                                    />
                                    <Button type="danger" onClick={() => removeItem(index)}>Remove</Button>
                                </div>
                            ))}
                            <Button type="dashed" onClick={addItem}>Add Item</Button>
                        </div>

                        <Form.Item className='mt-3' label="Total Amount">
                            <Input value={totalAmount} disabled />
                        </Form.Item>
                        {
                            isAuthenticated()?.role === 0 &&
                            <>
                                <div className='my-4 d-flex justify-content-between align-items-center'>
                                    <h4 className='mb-0'>Add Manual Payments</h4>
                                    <ManualPayments id={id} refreshParentData={handleRefreshData} />
                                </div>
                            </>
                        }
                        {
                            paymentRecords?.length > 0 &&
                            <div className='my-4 border p-3 rounded paymentRecords'>
                                <h4 className='mb-3'>All Payments</h4>
                                {
                                    paymentRecords?.map((payment, index) => {
                                        return (
                                            <div key={index} className='d-flex gap-3 justify-content-between flex-wrap border-top py-3'>
                                                <h6>{payment?.description}</h6>
                                                <h6>{formData?.currency} {payment?.amount}</h6>
                                                <h6>{payment?.date}</h6>
                                                <div className='w-auto'>
                                                    <Popconfirm
                                                        title="Delete"
                                                        description="Are you sure to delete this record?"
                                                        onConfirm={() => deletePaymentRecord(payment?.id)}
                                                        okText="Yes"
                                                        cancelText="No"
                                                    >
                                                        <DeleteOutlined />
                                                    </Popconfirm>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        }
                        <Button
                            type='primary'
                            htmlType="submit"
                            loading={loading}
                            disabled={loading}
                            className="w-100 mt-4"
                        >
                            Update Contract {paymentsUpdated && "& Send Receipt to Client"}
                        </Button>
                    </form>
            }
        </div>
    );
};

export default UpdateContract;
