import React, { useState } from 'react';
import axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import { setAuthentication } from '../../components/Auth/auth';
import './Auth.css';
import { ErrorAlert, SuccessAlert } from '../../components/Messages/Messages';
import { Button, Input } from 'antd';

export const Login = () => {
  const router = useHistory();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({
    email: '',
    password: ''
  });


  const handleChange = (e) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value
    });
  }


  const submitHandler = async (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    setLoading(true);
    await axios.post(`${process.env.REACT_APP_BACKEND_URL}/users/login`, userData).then(res => {
      setLoading(false);
      if (res.status === 200) {
        setAuthentication(res.data?.user, res.data.token);
        SuccessAlert(res.data.successMessage);
        const redirectUrl = localStorage.getItem("redirectUrl");
        if (redirectUrl) {
          router.push(redirectUrl);
        } else {
          router.push('/');
        }
        document.location.reload();
      }
      else {
        ErrorAlert(res.data.errorMessage);
      }
    }).catch(err => {
      setLoading(false);
      ErrorAlert(err?.message);
    })
  };


  return (
    <>
      <div className='auth'>
        <div className="auth-inner-bubble-container">
          <h2>Login</h2>
          <p>Login with email and password</p>
          <form onSubmit={submitHandler}>
            <div className='item'>
              <label>Email</label>
              <Input required name='email' type="text" className="form-control" placeholder="Email" onChange={handleChange} />
            </div>
            <div className='item'>
              <label>Password</label>
              <Input.Password required name='password' type="password" className="form-control" placeholder="Password" onChange={handleChange} />
            </div>
            <Button type='primary' className='mt-4 w-100' loading={loading} disabled={loading} htmlType="submit">Login</Button>
          </form>
          <div className='end-text'>
            <div>Forgot Password?</div>
            <Link to="/forgot-password">
              <b className='fw-bold'>Click here</b>
            </Link>
          </div>
          {/* <div className='end-text'>
            <div>Don't have an account?</div>
            <Link to="/signup">
              <b className='fw-bold'>Register</b>
            </Link>
          </div> */}
        </div>
      </div>
    </ >

  );
}
